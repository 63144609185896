import React, {useRef} from 'react'
import Footer from './Footer'
import Header from './Header'

export default function Home() {
    const videoRef = useRef(null);

  const handleOpenModal = () => {
 
    // Optionally, you can play the video when the modal opens
    videoRef.current.play();
  };

  const handleCloseModal = () => {
    
    // Stop the video when the modal is closed
    videoRef.current.pause();
    // Optionally, reset the video to the beginning
    videoRef.current.currentTime = 0;
  };
    return (
        <>
        <Header />
            <section className="hero-section">
                <img src="assets/images/banner.jpg" className="banner_img" alt="banner" />
                <div className="banner-content">
                    <h1 className="wow fadeInUp" data-wow-delay="0.4s">Zaevyul</h1>
                    <p className="wow fadeInUp" data-wow-delay="0.4s">Where Timeless Art meets Timeless Craft. We aim and aspire to revive, reclaim and regenerate the Pashmina (Cashmere) production and trade in its pristine form.</p>
                    <div className="scroll-downs wow fadeInUp" data-wow-delay="0.4s">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="second-section padding-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInUp order_2" data-wow-delay="0.6s">
                            <div className="left-content">
                                <img src="assets/images/img1.png" alt="" />
                                <a id="play-video" onClick={handleOpenModal} data-bs-toggle="modal" data-bs-target="#exampleModal" className="video-play-button" href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 order_1">
                            <div className="right-content">
                                <h1 className="heading-text wow fadeInUp" data-wow-delay="0.6s">Local with global scope</h1>
                                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s">Zaevyul reclaims the historic Pashmina
                                    (Cashmere) trail and journey. Our idiom is local but our scope global!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-three padding-section">
                <div className="head-section">
                    <h1 className="heading-text wow fadeInUp" data-wow-delay="0.6s">In the picturesque <br /> valley of Kashmir</h1>
                    <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s">and the glittering sun kissed Suru valley bound by majestic snow capped mountains and Leh, Zaevyul’s master craftsmen and craftswomen craft warmth for you.</p>
                </div>
                <div className="section-three-gallery">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 p_0  wow fadeInUp" data-wow-delay="0.4s">
                            <div className="img-box card-box">
                                <img src="assets/images/image.jpg" alt="images" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 p_0  wow fadeInUp" data-wow-delay="0.6s">
                            <div className="img-box card-box">
                                <img src="assets/images/image1.jpg" alt="images" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 p_0  wow fadeInUp" data-wow-delay="0.8s">
                            <div className="img-box card-box">
                                <img src="assets/images/image2.jpg" alt="images" />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-6 p_0  wow fadeInUp" data-wow-delay="1s">
                            <div className="img-box card-box">
                                <img src="assets/images/image3.jpg" alt="images" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-four padding-section">
                <div className="row">
                    <div className="col-lg-6 order_2">
                        <div className="left-content">
                            <img src="assets/images/left-img.jpg" className="mobile-view" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 order_1">
                        <div className="right-content">
                            <h1 className="heading-text wow fadeInUp" data-wow-delay="0.4s">Zaevyul’s belief</h1>
                            <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s"> Zaevyul’s firm belief and mission is in the creation of fusion and deep togetherness between the East and the West. Out motto is global <strong>‘I’</strong>, Global <strong>‘You’</strong>, and world style.</p>
                            <p className="secont-paragraph wow fadeInUp" data-wow-delay="0.8s">Zaevyul puts a premium on speaks to a diverse world. Our defining philosophy is fusion - a synthesis between the East and West and crafting deep togetherness between the two through art and craft.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-five">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/c-img1.jpg" data-dimbox="my-image">
                                <img src="assets/images/c-img1.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Zard Waeshnayr (Yellow Warmth).<br /> High End Merino Wool 100 % hand knitted with care and passion.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_1" data-wow-delay="0.5s">
                        <div className="img-box">
                            <div className="all-collection">
                                <h4>Zaevyul's Collection</h4>
                                <p>Zaevyul’s amazing collection of Poshvaein Pallav (durable apparel)</p>
                                <a href="collections.php">All Collection</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_3" data-wow-delay="0.6s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/c-img2.jpg" data-dimbox="my-image">
                                <img src="assets/images/c-img2.jpg" alt="c-img1" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Sormaiel Waeshnayr te Kantope Juir (Grey Warmth).<br /> High End Merino wool sweater and hat pair, 100 % hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_4" data-wow-delay="0.7s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/c-img3.jpg" data-dimbox="my-image">
                                <img src="assets/images/collections/two/4.jpg" alt="c-img1" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphayd Pashm Waeshnayr (White Pashmina/Cashmere Warmth).<br /> 100 % Pashmina(Cashmere) sweater, entirely hand knitted with care and warmth
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_5" data-wow-delay="0.8s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/c-img4.jpg" data-dimbox="my-image">
                                <img src="assets/images/c-img4.jpg" alt="c-img1" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Jalaar Daar Waeshnayr (Warmth with Design).<br />
                                        High End Merino Wool, 100 % hand knitted with care and passion.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_6" data-wow-delay="0.9s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/c-img5.jpg" data-dimbox="my-image">
                                <img src="assets/images/c-img5.jpg" alt="c-img1" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Munuil Waeshnayr te Dyekke Paet juir (Heavy woollen warmth).<br /> 100% classic sheep wool sweater and head band pair, entirely hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_7" data-wow-delay="1s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/c-img6.jpg" data-dimbox="my-image">
                                <img src="assets/images/collections/three/2.jpg" alt="c-img1" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Poorith Kong e Rang Stole (Enhanced Saffron like stole).<br /> 100 % Pashmina(Cashmere) stole, entirely hand spun, hand woven and naturally dyed (saffron).
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_8" data-wow-delay="1.1s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/c-img7.jpg" data-dimbox="my-image">
                                <img src="assets/images/c-img7.jpg" alt="c-img1" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Sabz Kantope (Green Hat).<br /> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_8" data-wow-delay="1.1s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/one/1.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/one/1.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Mohuir Dyaekke Paet (Black Head Band).<br /> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_8" data-wow-delay="1.1s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/c-img9.jpg" data-dimbox="my-image">
                                <img src="assets/images/collections/three/6.jpg" alt="c-img1" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Poorith Badam rang Stole (Enhanced 100 % Pashmina (Cashmere).<br /> Entirely hand crafted, hand woven and naturally dyed(light brown) stole.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_8" data-wow-delay="1.1s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/10.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/10.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Zard Dyaekke Paet (Yellow Head band).<br /> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 wow fadeInUp order_8" data-wow-delay="1.1s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/one/6.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/one/6.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphaed Pashm Cantope. 100 % Pashmina ( Cashmere)<br /> hat, entirely hand spun and hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section-six">
                <div className="row">
                    <div className="col-md-6 col-sm-6 img-slice-wrap img-slice-wrap-home over-hide">
                        <a className="parallax-elements js-tilt" href="#" data-tilt-perspective="800" data-tilt-speed="700" data-tilt-max="10">
                            <div className="scroll-img" style={{backgroundImage: 'url("assets/images/c-1.jpg")'}}></div>
                            
                        </a>
                    </div>
                    <div className="col-md-6 col-sm-6 img-slice-wrap img-slice-wrap-home over-hide">
                        <a className="parallax-elements js-tilt" href="#" data-tilt-perspective="800" data-tilt-speed="700" data-tilt-max="10">
                            <div className="scroll-img" style={{backgroundImage: 'url("assets/images/c-2.jpg")'}}></div>
                        
                        </a>
                    </div>
                    <div className="col-md-6 col-sm-6 img-slice-wrap img-slice-wrap-home over-hide">
                        <a className="parallax-elements js-tilt" href="#" data-tilt-perspective="800" data-tilt-speed="700" data-tilt-max="10">
                            <div className="scroll-img" style={{backgroundImage:  'url("assets/images/c-3.jpg")'}}></div>

                            
                        </a>
                    </div>
                    <div className="col-md-6 col-sm-6 img-slice-wrap img-slice-wrap-home over-hide">
                        <a className="parallax-elements js-tilt" href="#" data-tilt-perspective="800" data-tilt-speed="700" data-tilt-max="10">
                            <div className="scroll-img" style={{backgroundImage:  'url("assets/images/c-4.jpg")'}}></div>

                            
                        </a>
                    </div>
                    <div className="col-md-6 col-sm-6 img-slice-wrap img-slice-wrap-home over-hide">
                        <a className="parallax-elements js-tilt" href="#" data-tilt-perspective="800" data-tilt-speed="700" data-tilt-max="10">
                            <div className="scroll-img" style={{backgroundImage: 'url("assets/images/c-5.jpg")'}}></div>

                            
                        </a>
                    </div>
                    <div className="col-md-6 col-sm-6 img-slice-wrap img-slice-wrap-home over-hide">
                        <a className="parallax-elements js-tilt" href="#" data-tilt-perspective="800" data-tilt-speed="700" data-tilt-max="10">
                            <div className="scroll-img" style={{backgroundImage: 'url("assets/images/c-6.jpg")'}}></div>

                           
                        </a>
                    </div>
                </div>
            </div>
            <section className="second-section section-seven padding-section">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-6">
                            <div className="right-content">
                                <h1 className="heading-text wow fadeInUp" data-wow-delay="0.6s">All over the globe!</h1>
                                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.7s">Empress Josephine, Napoleon I’s wife made Cashmere shawls a rage in Europe in the 18th and 19th centuries. You and us together can do it again-this time around all over the globe!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.6s">
                            <div className="left-content">
                                <img src="assets/images/right-img.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <img src="assets/images/Motif.jpg" className="fixed-img" alt="" />
            </section>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered video-popup-box">
                    <div className="modal-content">
                        <div className="modal-body ">
                            <button type="button" onClick={handleCloseModal} className="btn-close" id="btnclose" data-bs-dismiss="modal" aria-label="Close"></button>
                            <video id="video_link" ref={videoRef} src="assets/images/Zaevyulvideofinal.mp4" loop controls></video>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

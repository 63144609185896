import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Faq() {
    return (
        <>
            <div className="inner_header">
                <Header/>
            </div>
            <section className="hero-section inner-banner">
                <div className="banner-content">
                    <h1 className="wow fadeInUp" data-wow-delay="0.4s">Faq's</h1>
                </div>
            </section>

            <section className="section-six section-faq padding-section">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="one">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What does Zaevyul mean?
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="one" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Zaevyul means delicate in Kashmiri- the quintessence of genuine and authentic Pashmina(Cashmere) products.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="two">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Are Zaevyul’s products 100% authentic?
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="two" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Our belief and motto is authenticity and originality. Except for our high end Merino wool products where Merino is pre-ordered but entirely handknitted by us, all
                                other products are 100 % Pashmina(Cashmere).
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="three">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Are Zaevyul’s products hand spun, hand woven and hand knitted?
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="three" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Our Pashmina(Cashmere) products are 100% hand spun and hand woven and hand knitted. The same applies to our Merino line products.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="four">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Where does Zaevyul source Pashmina (Cashmere) from?
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="four" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                We source Pashmina(Cashmere) from Leh(Ladakh)
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="five">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Does Zaevyul offer customized products?
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="five" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Yes, we will do that. But the prices of customized products will be higher and may take longer in making.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="six">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                What is Zaevyul’s refund and return policy?
                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="six" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Zaevyul will make a partial or full refund or take the product back if and when a genuine product is established or when the product delivered does not correspond to the product delivered. The same holds for our return policy.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="seven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                How are complaints resolved by Zaevyul?
                            </button>
                        </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="seven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Because all (except for our Merino wool range products which are only hand knitted) our Pashmina(Cashmere) products are hand spun and hand woven, there cannot be uniformity (in texture and to some extent form) of our product range, we reserve the prerogative to determine the nature of a given complaint and its resolution by the parameters of quality, fairness and excellent customer service.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="eight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Within what time should a genuine complaint be formed about a product?
                            </button>
                        </h2>
                        <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="eight" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Within 24 hours of the receipt of a product
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="nine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                How long will it take Zaevyul to resolve a complaint?

                            </button>
                        </h2>
                        <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="nine" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                15-25 days

                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="ten">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                Does Zaevyul use natural, organic dyes for its products?

                            </button>
                        </h2>
                        <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="ten" data-bs-parent="#accordionExample">
                            <div className="accordion-body">

                                <p>Except for its pre- ordered collection, we use natural organic dyes for our products.</p>

                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="eleven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                What other category of wool does Zaevyul use?

                            </button>
                        </h2>
                        <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="eleven" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                Except for our pre-ordered collection of merino wool fiber, we use natural, organic dyes for our products.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}

import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { getDiscountPrice } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import { post } from "../../helpers/helper_api";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";

const ProductGridListSingle = ({
  product,
  currency,
  cartItem,
  wishlistItem,
  compareItem,
  spaceBottomClass,
  addToWishlistFun,
  addToCart
}) => {
  const [modalShow, setModalShow] = useState(false);
  const discountedPrice = getDiscountPrice(product.price, product.discount);
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { user } = useSelector((state) => state?.user);

  const [loading, setLoading] = useState(false);

  const detailPage = () => {

  }


  console.log('product', product?.catId)
  return (
    <Fragment>

      <div className={clsx("product-wrap", spaceBottomClass)}>
        <div className="product-img">
          <Link to={"/product/" + product._id}>
            <img
              className="default-img"
              src={product.images[0]}
              alt=""
            />
            {/* {product.images.length > 1 ? (
                <img
                  className="hover-img"
                  src={product.images[1]}
                  alt=""
                />
              ) : (
                ""
              )} */}
          </Link>
          {product.offPrice || product.price ? (
            <div className="product-img-badges">
              {product.offPrice ? (
                <span className="pink">-{product.offPrice}%</span>
              ) : (
                ""
              )}
              {product.price ? <span className="purple">New</span> : ""}
            </div>
          ) : (
            ""
          )}

          <div className="product-action">
            <div className="pro-same-action pro-wishlist">
              <button
                className={product?.liked == true ? "active" : ""}
                // disabled={product?.liked !== false}
                title={
                  product?.liked == true
                    ? "Added to wishlist"
                    : "Add to wishlist"
                }
                onClick={() => { addToWishlistFun(product?._id) }}
              >
                {product?.liked == true ? <i className="fa fa-heart" /> : <i className="fa fa-heart-o" />}
              </button>
            </div>
            <div className="pro-same-action pro-cart">
              {product.affiliateLink ? (
                <a
                  href={product.affiliateLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  Buy now{" "}
                </a>
              ) : product.variation && product.variation.length >= 1 ? (
                <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                  Select Option
                </Link>
              ) : product.stock && product.stock > 0 ? (
                <button
                  onClick={() => addToCart(product?._id)}
                  className=
                  {product?.carted ? "active" : ""}
                  disabled={product?.carted}
                  title={
                    product?.carted ? "Added to cart" : "Add to cart"
                  }
                >
                  {" "}
                  <i className="pe-7s-cart"></i>{" "}
                  {product?.carted ? "Added" : "Add to cart"}
                </button>
              ) : (
                <button disabled className="active">
                  Out of Stock
                </button>
              )}
            </div>
            <div className="pro-same-action pro-quickview">
              <Link to={"/product/" + product._id} ><button title="Quick View">
                <i className="pe-7s-look" />
              </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="product-content text-center">
          <h3>
            <Link to={"/product/" + product._id} >
              {product.title}
            </Link>
          </h3>
          {product.rating && product.rating > 0 ? (
            <div className="product-rating">
              <Rating ratingValue={product.rating} />
            </div>
          ) : (
            ""
          )}
          <div className="product-price">
            {/* {discountedPrice !== null ? ( */}
            <Fragment>
              ₹<span>{product?.price}</span>{" "}
              ₹<span className="old">
                {product?.offPrice}
              </span>
            </Fragment>
            {/* ) : (
                <span>{currency.currencySymbol + finalProductPrice} </span>
              )} */}
          </div>
        </div>
      </div>
      <div className="shop-list-wrap mb-30">
        <div class="row">
          <div class="col-xl-4 col-md-5 col-sm-6">
            <div class="product-list-image-wrap">
              <div class="product-img">
                <Link to={"/product/" + product._id}><img class="default-img img-fluid" src={product.images[0]} alt="" />
                <img class="hover-img img-fluid" src={product.images[0]} alt="" /></Link>
                <div class="product-img-badges"><span class="pink">{product.offPrice}%</span></div>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-md-7 col-sm-6">
            <div class="shop-list-content">
              <h3><Link to={"/product/" + product._id}>{product?.title}</Link></h3>
              <div class="product-list-price"><span>{product?.price}</span> <span class="old">{product?.offPrice}</span></div>
              <div class="rating-review">
                <div class="product-list-rating"><i class="fa fa-star-o yellow"></i><i class="fa fa-star-o yellow"></i><i class="fa fa-star-o yellow"></i><i class="fa fa-star-o yellow"></i><i class="fa fa-star-o"></i></div>
              </div>
              <p dangerouslySetInnerHTML={{ __html: product?.desc }}>

              </p>
              <div class="shop-list-actions d-flex align-items-center">
                <div class="shop-list-btn btn-hover">
                  <button
                    onClick={() => addToCart(product?._id)}
                    className=
                    {product?.carted ? "active" : ""}
                    disabled={product?.carted}
                    title={
                      product?.carted ? "Added to cart" : "Add to cart"
                    }
                  >
                    {" "}
                    <i className="pe-7s-cart"></i>{" "}
                    {product?.carted ? "Added" : "Add to cart"}
                  </button>
                </div>
                <div class="shop-list-wishlist ml-10">
                  <button
                    className={product?.liked == true ? "active" : ""}
                    // disabled={product?.liked !== false}
                    title={
                      product?.liked == true
                        ? "Added to wishlist"
                        : "Add to wishlist"
                    }
                    onClick={() => { addToWishlistFun(product?._id) }}
                  >
                    {product?.liked == true ? <i className="fa fa-heart" /> : <i className="fa fa-heart-o" />}
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      {/* <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedPrice={discountedPrice}
        finalProductPrice={finalProductPrice}
        finalDiscountedPrice={finalDiscountedPrice}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
      /> */}
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.shape({})
};

export default ProductGridListSingle;

import React, { Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { AvForm, AvField, AvGroup, AvAvField, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { FormGroup } from "reactstrap";
import { post } from "../../helpers/helper_api";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/slices/user-slice";
const ResetPassword = () => {
    let { pathname } = useLocation();
    let dispatch = useDispatch();
    let navigation = useNavigate();

    const handleValidSubmit1 = (e, v) => {
        let body = {
            ...v,
        }
        post('/forgot', body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    dispatch(setUser(res?.data));
                    localStorage.setItem('user', JSON.stringify(res?.data))
                    navigation('/')
                }
            }).catch((err) => {
                console.log(err)
            })
    }
    return (
        <Fragment>
            <SEO
                titleTemplate="Login"
                description="Login page of flone react minimalist eCommerce template."
            />
            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Login Register", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="login-register-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 ms-auto me-auto">
                                <div className="login-register-wrapper">
                                    <Tab.Container defaultActiveKey="login">
                                        <Nav variant="pills" className="login-register-tab-list">
                                            <Nav.Item>
                                                <Nav.Link eventKey="login">
                                                    <h4>Forgot Password</h4>
                                                </Nav.Link>
                                            </Nav.Item>

                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="login">
                                                <div className="login-form-container">
                                                    <div className="login-register-form">
                                                        <AvForm onValidSubmit={handleValidSubmit1}>
                                                            <AvField
                                                                type="password"
                                                                name="password"
                                                                placeholder="Password"
                                                            />
                                                            <AvField
                                                                type="password"
                                                                name="cpassword"
                                                                placeholder="Confirm Password"
                                                            />
                                                            <div className="button-box">

                                                                <button type="submit">
                                                                    <span>Login</span>
                                                                </button>
                                                            </div>
                                                        </AvForm>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    );
};

export default ResetPassword;

import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <img src="assets/images/logo.png" alt="logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mb-2">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/shop">shop</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/our-story">our story</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/collections">collections</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/blogs">blogs</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/in-the-press">in the press</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/faq">faq's</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

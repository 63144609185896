import { AvField, AvForm } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { get, post } from "../../../helpers/helper_api";
import { toast } from "react-toastify";
import { useEffect } from "react";

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () => {
    email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value
      });

    let emailInput = document.getElementById("mc-form-email");
    emailInput.value = "";
  };



  const handleValidSubmit = (e, values) => {
    let body = {
      ...values,
    }
    post('/subscribe', body)
      .then((res) => {
        if (res?.statusCode == 200)
          toast.success(res?.message);
        if (res?.statusCode == 208)
          toast.success(res?.error);
      }).catch((error) => {
        console.log(error);
      })
  }

  return (
    <div className="subscribe-form">
      <div className="mc-form">
        <AvForm
          // ref={ref}
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <div>
            <AvField
              name="email"
              className="email"
              // ref={node => (email = node)}
              type="email"
              placeholder="Enter your email address..."
            />
          </div>
          <div className="clear">
            <button className="button">
              SUBSCRIBE
            </button>
          </div>
        </AvForm>
      </div>

      {status === "sending" && (
        <div style={{ color: "#3498db", fontSize: "12px" }}>sending...</div>
      )}
      {status === "error" && (
        <div
          style={{ color: "#e74c3c", fontSize: "12px" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "#2ecc71", fontSize: "12px" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

const SubscribeEmail = ({ mailchimpUrl }) => {
  return (
    <div>
      <MailchimpSubscribe
        url={mailchimpUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

SubscribeEmail.propTypes = {
  mailchimpUrl: PropTypes.string
};

export default SubscribeEmail;

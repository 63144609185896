import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Collections() {
    return (
        <>
            <div className="inner_header">
                <Header />
            </div>
            <section className="hero-section inner-banner inner_banner">

                <div className="banner-content">
                    <h1 className="wow fadeInUp" data-wow-delay="0.4s">Zaevyul’s Collection</h1>
                    <p className="wow fadeInUp" data-wow-delay="0.6s">Zaevyul’s amazing collection of Poshvaein Pallav <br/>(durable apparel)</p>
                </div>
            </section>
            <section className="section-five" style={{backgroundColor: 'transparent !important'}}>
                <div className="row">

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/4.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/4.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphayd Pashm Waeshnayr (White Pashmina/Cashmere Warmth).<br/> 100 % Pashmina(Cashmere) sweater, entirely hand knitted with care and warmth
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/5.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/5.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphayd Pashm Waeshnayr (White Pashmina/ Cashmere warmth te Kantope Juir).<br/> 100 % Pashmina (Cashmere) sweater, entirely hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/9.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/9.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphayd Pashm Waeshnayr te Vozul Kantope juir (White Pashmina/Cashmere warmth).<br/> 100 % Pashmina(Cashmere) and red hat pair, entirely hand knitted with care and warmth.

                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/8.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/8.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphayd Pashm Waeshnayr te Sabz Kantope juir (White Pashmina/Cashmere warmth).<br/> Saphayd Pashm Waeshnayr te Sabz Kantope juir (White Pashmina/Cashmere warmth).
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/three/2.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/three/2.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Poorith Kong e Rang Stole (Enhanced Saffron like stole).<br/> 100 % Pashmina(Cashmere) stole, entirely hand spun, hand woven and naturally dyed (saffron).
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/three/3.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/three/3.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Poorith raz daar stole (Enhanced striped stole).<br/>100 % Pashmina(Cashmere) stole, entirely hand spun, hand woven and naturally dyed.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/three/1.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/three/1.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Poorith Saphayd Stole (Enhanced 100% Pashmina(Cashmere).<br/>
                                            Entirely hand crafted, hand woven and natural white stole.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/three/4.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/three/4.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Poorith Sormaeil rang Stole (Enhanced 100 % Pashmina(Cashmere).<br/> Grey color entirely hand spun, hand woven and naturally dyed(grey) stole.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/three/6.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/three/6.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Poorith Badam rang Stole (Enhanced 100 % Pashmina (Cashmere).<br/> Entirely hand crafted, hand woven and naturally dyed(light brown) stole.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/three/8.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/three/8.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        14 Poorith Vaangein Stole (Enhanced 100 % Pashmina(Cashmere).<br/> Entirely hand spun and hand woven and naturally dyed purple hue color.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/one/6.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/one/6.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphaed Pashm Cantope. 100 % Pashmina ( Cashmere)<br/> hat, entirely hand spun and hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/1.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/1.jpg" alt="image" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Muniul Waeshnayr (Heavy Woollen Warmth).<br/> Classic 100% sheep wool sweater, entirely hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/6.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/6.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Munuil Waeshnayr (Heavy Woollen Warmth).<br/> Classic 100% sheep wool sweater, entirely hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/7.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/7.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Munuil Waeshnayr te Kantope juir (Heavy woollen warmth).<br/> Classic 100% sheep wool sweater and hat pair, entirely hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/14.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/14.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Munuil Waeshnayr te Dyekke Paet juir (Heavy woollen warmth).<br/> 100% classic sheep wool sweater and head band pair, entirely hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/15.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/15.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Aasmaein Waeshnayr te Kantope Juir (Sky Blue Warmth).<br/> High end Merino wool sweater and hat pair, 100 % hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/16.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/16.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Sormaiel Waeshnayr te Kantope Juir (Grey Warmth).<br/> High End Merino wool sweater and hat pair, 100 % hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/17.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/17.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Jalaar Daar Waeshnayr (Warmth with Design).<br/>
                                            High End Merino Wool, 100 % hand knitted with care and passion.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/18.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/18.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Niul Waeshnayr te Kantope juir (Blue Warmth).<br/> High end merino wool sweater and hat pair, entirely hand knitted with care and warmth.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/19.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/19.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Jalaar Daar Waeshnayr (Warmth with Design).<br/>
                                            High End Merino Wool, 100 % hand knitted with care and passion.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/21.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/21.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Sormaeil Waeshnayr (Grey Warmth).<br/> High End Merino wool, entirely hand knitted with care and warmth.

                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/two/22.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/two/22.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Zard Waeshnayr (Yellow Warmth).<br/> High End Merino Wool 100 % hand knitted with care and passion.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>


                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/2.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/2.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Vozul Kantope (Red Hat).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/3.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/3.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Sabz Kantope (Green Hat).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/4.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/4.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Sabz Kantope (Green Hat).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/5.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/5.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Niuul Kantope (Blue Hat).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/6.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/6.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphaed Kantope (White Hat).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/7.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/7.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Sabz Kantope (Green Hat).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/8.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/8.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Vozzej Dyaekke Paet (Red Head Band).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/9.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/9.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Niuul Dyaekke Paet (Blue Head band).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/10.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/10.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Zard Dyaekke Paet (Yellow Head band).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/four/11.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/four/11.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Aasmaein Dyaekke Paet (Sky Blue Head Band).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/one/1.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/one/1.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Mohuir Dyaekke Paet (Black Head Band).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/one/2.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/one/2.jpg" alt="images" />

                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/one/3.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/one/3.jpg" alt="images" />

                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/one/4.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/one/4.jpg" alt="images" />
                                <div className="hover-box-content">
                                    <p className="paragraph-text wow fadeInUp">
                                        Saphaed Kantope (White Hat).<br/> High end Merino Wool entirely hand knitted.
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-6 wow fadeInUp order_2" data-wow-delay="0.4s">
                        <div className="img-box">
                            <a className="example-image-link" href="assets/images/collections/one/5.jpg" data-dimbox="my-image">
                                <img className="example-image" src="assets/images/collections/one/5.jpg" alt="images" />

                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

import PropTypes from "prop-types";

import { setActiveSort } from "../../helpers/product";
import { useEffect, useState } from "react";
import { get } from "../../helpers/helper_api";

const ShopCategories = ({ categories, getSortParams }) => {
  const [category, setCategory] = useState([]);

useEffect(()=>{
    getCat();
},[])

const getCat = () =>{
  get('/category/drop')
  .then((res)=>{
    if(res?.statusCode == 200){
      setCategory(res?.data);
    }
  })
  .catch((err)=>{
    console.log(err)
  })
}

  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">Categories </h4>
      <div className="sidebar-widget-list mt-30">
        {category ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  onClick={e => {
                    getSortParams("category", "");
                    setActiveSort(e);
                  }}
                >
                  <span className="checkmark" /> All Categories
                </button>
              </div>
            </li>
            {category.map((category, key) => {
              return (
                <li key={key}>
                  <div className="sidebar-widget-list-left">
                    <button
                      onClick={e => {
                        getSortParams("category", category?._id);
                        setActiveSort(e);
                      }}
                    >
                      {" "}
                      <span className="checkmark" /> {category?.title}{" "}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func
};

export default ShopCategories;

import { useState } from "react";


const ShopSearch = ({getSearchValue}) => {
  const [value, setValue] = useState('');
  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">Search </h4>
      <div className="pro-sidebar-search mb-50 mt-25">
        <form className="pro-sidebar-search-form">
          <input type="text" placeholder="Search here..." onChange={(e)=>setValue(e.target.value)} />
          <button type="button" onClick={()=>getSearchValue(value)}>
            <i className="pe-7s-search" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ShopSearch;

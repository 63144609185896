import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUser } from '../../store/slices/user-slice'

export default function Logout() {
    let navigate = useNavigate()
    let dispatch = useDispatch()
    useEffect(() => {
        localStorage.removeItem('user')
        dispatch(setUser(null))
        if(!localStorage.getItem('user'))
        navigate('/')
      })
  return (
    <div>Logout</div>
  )
}

import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function OurStory() {
    return (
        <>
        <div className="inner_header"><Header /></div>
            <section className="hero-section inner-banner">
                <div className="banner-content">
                    <h1 className="wow fadeInUp" data-wow-delay="0.4s">Our Story</h1>
                </div>
            </section>
            <div className="img-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="img-p">
                                <img src="assets/images/img-profile.jpg" />
                            </div>
                        </div>
                        <div className="col-lg-7 profile-paragrap">
                            <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s">
                                Zaevyul's founder, Wajahat Qazi, is inspired by the creative genius and talent of craftsmen and craftswomen, their survival and welfare, socially conscious and responsible, ethical business and commerce.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="inner-banner-section">
                <img src="assets/images/about-1.jpg" className="banner_img" alt="banner" />
            </section>
            <div className="second-section our-story-section padding-section">
                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s">Zaevyul - which means delicate in Kashmiri language- is on a mission: We aim and aspire to revive, reclaim and regenerate Pashmina production and trade in its pristine form. These 3 R’s of Zaevyul that animate and constitute our calling are a natural fit with our commitment to social good and ethical, transparent, Eco-sustainable business, survival and welfare of artisans whose timeless art and craft faces grave threats. We see these in complementary and not in mutually exclusive terms. </p>
            </div>

            <div className="second-section our-story-section padding-section">
                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s">Founded in 2022, Zaevyul seeks to revive the Pashmina(Cashmere) production and trade as it used to occur - tracing the Pashmina(Cashmere) trail from Ladakh, to Kashmir and the world, at large historically part of the Silk Route in a new fresh idiom and new pathways. All this is to be done by both presenting traditional and indigenous Pashmina(Cashmere) products, and their synthesis with modern trends and designs by the co-creation of value.</p>

            </div>
            <section className="inner-banner-section">
                <img src="assets/images/about-2.jpg" className="banner_img" alt="banner" />
            </section>
            <div className="second-section our-story-section padding-section">

                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s">Through this synthesis, we aim to craft a ‘new form of togetherness’ between the East and the West where instead of the frisson created by cultural appropriation of Pashmina(Cashmere) by decontextualization of its origins, there is creative fusion. This broad approach is complemented and augmented by Zaevyul’s deep commitment to create a new paradigm where artisans of the Kashmir and Ladakh regions are connected to the world in a way that allows them to not just survive but thrive in a world where lack of income growth, mass production, commoditization, fake substitutes and fast fashion ‘conspire’ to make their art and craft vanish.</p>
            </div>
            <section className="inner-banner-section">
                <img src="assets/images/about-3.jpg" className="banner_img" alt="banner" />
            </section>
            <div className="second-section our-story-section padding-section">
                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s">All this Zaevyul will render possible by a vigorous focus on authenticity, ethics, sustainability and transparency.
                    Our business model is based upon Eco-sustainability, community empowerment, sound, safe and pleasant working conditions of its team, a living wage more than the existing industry standards and benchmarks for corresponding industries and the handicrafts industry in the region.
                </p>

            </div>
            <section className="inner-banner-section">
                <img src="assets/images/about-4.jpg" className="banner_img" alt="banner" />
            </section>
            <div className="second-section our-story-section padding-section">


                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s">Over this Zaevyul will distribute a significant portion of the profit margin of each product sold to the team member(s) that makes a given product. In lieu of its commitment to Eco-sustainability Zaevyul will be to be part of the circular economy: we will after a threshold time of two years take back products from consumers who would like to be part of this paradigm and then re-engineer these as part of our quest toward continuous product development and re-development in accord with environmental sustainability. Our packaging will, corresponding with highest environmental standards, be made from recyclable organic material. And we shall be mostly using organic dyes for our products.</p>
                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s"> Zaevyul is on a mission. We have taken the first steps on a road that is challenging, demanding and formidable. But we are committed and devoted to it! Let’s make the ongoing journey together!</p>
                <p className="paragraph-text wow fadeInUp" data-wow-delay="0.6s"><strong>Zaevyul (OPC) Private Limited.</strong></p>
            </div>
            <Footer/>
        </>
    )
}

import { Fragment, useState, useEffect } from 'react';
import Paginator from 'react-hooks-paginator';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"
import { getSortedProducts } from '../../helpers/product';
import SEO from "../../components/seo";
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import ShopSidebar from '../../wrappers/product/ShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';
import { post } from '../../helpers/helper_api';
import { setProducts } from '../../store/slices/product-slice';
import { store } from '../../store/store';
import Loader from '../../components/loader/Loader';
import { toast } from 'react-toastify';
import { setUser } from '../../store/slices/user-slice';

const Shop = ({ location }) => {
    const [layout, setLayout] = useState('grid three-column');
    const [sortType, setSortType] = useState('');
    const [sortValue, setSortValue] = useState('');
    const [filterSortType, setFilterSortType] = useState('');
    const [filterSortValue, setFilterSortValue] = useState('');
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [sortedProducts, setSortedProducts] = useState([]);
    const [data, setData] = useState([]);
    const [str, setStr] = useState('')
    const { products } = useSelector((state) => state.product);
    const { user } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const pageLimit = 15;
    let { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getLayout = (layout) => {
        setLayout(layout)
    }

    // console.log('shop page', user)

    useEffect(() => {
        getData()
    }, [sortValue, str])

    const getData = () => {
        if (!loading) {
            setLoading(true)
            let body = {
                catId: sortValue,
                str: str,
                userId: user?._id
            }
            post('/product/list', body)
                .then((res) => {
                    setLoading(false)
                    if (res?.statusCode == 200) {
                        setData(res?.data)
                        // store.dispatch(setProducts(res?.data));
                        // setCurrentData(res?.data);
                    }
                }).catch((err) => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }

    const addToWishlistFun = (id) => {
        if (!loading) {
            setLoading(true)
            let body = {
                productId: id,
                token: user?.token
            }
            if (user) {
                post('/product/like', body)
                    .then((res) => {
                        setLoading(false)
                        if (res?.statusCode == 200) {
                            dispatch(setUser({ ...user, wish: res?.data }));
                            localStorage.setItem("user", JSON.stringify({ ...user, wish: res?.data }));
                            toast.success(res?.message);
                            getData();
                        } else {
                            toast.error(res?.error)
                        }
                    }).catch((err) => {
                        console.log(err)
                        toast.error(err)
                        setLoading(false)
                    })
            } else {
                navigate('/login-register')
            }
        }
    }

    const addToCart = (id) => {
        // console.log("adding to cart");
        if (!loading) {
            setLoading(true)
            let body = {
                productId: id,
                token: user?.token
            }
            if (user) {
                post('/product/add_cart', body)
                    .then((res) => {
                        setLoading(false)
                        if (res?.statusCode == 200) {
                            dispatch(setUser({ ...user, cart: res?.data }));
                            localStorage.setItem("user", JSON.stringify({ ...user, cart: res?.data }));
                            toast.success(res?.message);
                            getData();
                        } else {
                            toast.error(res?.error)
                        }
                    }).catch((err) => {
                        console.log(err)
                        toast.error(err)
                        setLoading(false)
                    })
            } else {
                navigate('/login-register')
            }
        }
    }

    // console.log('cat id', sortValue)

    const getSortParams = (sortType, sortValue) => {
        let catId = [];

        if (sortValue) {
            catId?.push(sortValue)
        } else {
            catId = []
        }
        setSortType(sortType);
        setSortValue(catId);
        console.log('sortValue', sortValue)
    }

    const getSearchValue = (str) => {
        setStr(str)
    }

    const getFilterSortParams = (sortType, sortValue) => {
        setFilterSortType(sortType);
        setFilterSortValue(sortValue);
    }

    useEffect(() => {
        let sortedProducts = getSortedProducts(products, sortType, sortValue);
        const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);
        sortedProducts = filterSortedProducts;
        // setSortedProducts(sortedProducts);
        setCurrentData(data);
    }, [offset, products, sortType, sortValue, filterSortType, filterSortValue]);

    return (
        <Fragment>
            <SEO
                titleTemplate="Shop Page"
                description="Shop page of flone react minimalist eCommerce template."
            />
            <Loader loading={loading} />

            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Shop", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />

                <div className="shop-area pt-95 pb-100">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3 order-2 order-lg-1">
                                {/* shop sidebar */}
                                <ShopSidebar getSearchValue={getSearchValue} products={products} getSortParams={getSortParams} sideSpaceClass="mr-30" />
                            </div>
                            <div className="col-lg-9 order-1 order-lg-2">
                                {/* shop topbar default */}
                                <ShopTopbar getLayout={getLayout} getFilterSortParams={getFilterSortParams} productCount={products.length} sortedProductCount={currentData.length} />

                                {/* shop page content default */}
                                <ShopProducts addToCart={addToCart} addToWishlistFun={addToWishlistFun} layout={layout} products={data} />

                                {/* shop product pagination */}
                                <div className="pro-pagination-style text-center mt-30">
                                    <Paginator
                                        totalRecords={sortedProducts.length}
                                        pageLimit={pageLimit}
                                        pageNeighbours={2}
                                        setOffset={setOffset}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        pageContainerClass="mb-0 mt-0"
                                        pagePrevText="«"
                                        pageNextText="»"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    )
}

export default Shop;
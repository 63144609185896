import React from 'react'

export default function Footer() {
    return (
        <>
            <footer className='front-footer'>
                <img src="assets/images/footer.png" className="footer-bg" alt="" />
                <form>
                    <div className="footer-form wow fadeInUp" data-wow-delay="0.6s">
                        <input type="email" className="form-control" id="email" placeholder="enter email" />
                        <button type="button" className="btn">Submit</button>
                    </div>
                    <h5 className="wow fadeInUp" data-wow-delay="0.7">Subscribe to our mailing list</h5>
                    <p className="wow fadeInUp" data-wow-delay="0.7">We promise not to inundate your inbox and will only send emails occassionally, giving you exclusive access to our private sales and first looks at new collections.</p>
                    <p className="p-1 wow fadeInUp" data-wow-delay="0.7">We respect your privacy.</p>




                </form>
            </footer>
            <div className="copy-right-section " >
                <div className="container-fluid wow fadeInUp" data-wow-delay="0.4s">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="footer-link-1">
                                <ul>
                                    <li><a href="#">Blog</a></li>
                                    <li className="line_">|</li>
                                    <li><a href="#">News & Updates</a></li>
                                    <li className="line_">|</li>
                                    <li><a href="#">Terms of use</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-link-2">
                                <ul>
                                    <li><a href="https://www.facebook.com/zaevyul" target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>
                                    <li><a href="https://www.instagram.com/zaevyul/" target="_blank"><i className="fa-brands fa-instagram"></i></a></li>

                                    <li><a href="https://www.linkedin.com/company/zaevyul/" target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="footer-link-3">
                                <p>2023 © Zaevyul (OPC) Private Limited. Design by <a href="https://www.rajmith.com/">RAJMITH</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SEO from "../../components/seo";
import { getDiscountPrice } from "../../helpers/product";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { addToCart, decreaseQuantity, deleteFromCart, deleteAllFromCart } from "../../store/slices/cart-slice";
import { cartItemStock } from "../../helpers/product";
import { get, post, put } from "../../helpers/helper_api";
import { toast } from "react-toastify";
import { setUser } from "../../store/slices/user-slice";

const OrderList = () => {
  let cartTotalPrice = 0;
  const navigate = useNavigate();
  const [quantityCount] = useState(1);
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);
  const [totalProduct, setTotalProduct] = useState(0)
  const [grandTotal, setGrandTotal] = useState(0)

  useEffect(() => {
    if (user?.token) {
      getData();
    }
  }, [user]);

  const getData = () => {
    if (!loading) {
      setLoading(true);
      get("/user/cart", { token: user?.token })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            setData(res?.data);
            // setTotal(Number(res?.data?.productData?.price*res?.quen))
           let temp = [];
           res?.data?.map((item)=>{
            temp.push(item?.productData?.price*item?.quen)
           })
            const keyToReduce = 'quen';
            const keyPrice = 'price';
            const sum = res?.data.reduce((accumulator, currentItem) => {
              return accumulator + currentItem[keyToReduce];
            }, 0);

            let sum1 = temp?.reduce((accumulator, currentItem) => {
              return accumulator + currentItem;
            }, 0);
            setGrandTotal(sum1)
            // console.log('sum',sum); 
            setTotalProduct(res?.data?.length)
            let ids = [];
            res?.data?.forEach(item => {
              ids.push({ productId: item?.productId, quen: item?.quen });
            })
            dispatch(setUser({ ...user, cart: ids }));
            localStorage.setItem("user", JSON.stringify({ ...user, cart: ids }));
          } else if (res?.statusCode == 404) {
            dispatch(setUser({ ...user, cart: [] }));
            localStorage.setItem("user", JSON.stringify({ ...user, cart: [] }));
            setData([]);
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log(err);
          setLoading(false);
          toast.error("Something Went Wrong!");
        })
    }
  }



  console.log('total', totalProduct)

  const deleteCart = (id) => {
    if (!loading) {
      setLoading(true)
      let body = {
        productId: id,
        token: user?.token
      }
      if (user) {
        post('/product/add_cart', body)
          .then((res) => {
            setLoading(false)
            if (res?.statusCode == 200) {
              toast.success(res?.message);
              getData();
            } else {
              toast.error(res?.error)
            }
          }).catch((err) => {
            console.log(err)
            toast.error("Something Went Wrong!")
            setLoading(false)
          });
      } else {
        navigate('/login-register')
      }
    }
  }

  const deleteAll = () => {
    if (!loading) {
      setLoading(true);
      put("/user/update", { token: user?.token, cart: [] })
        .then(res => {
          setLoading(false);

          if (res?.statusCode == 200) {
            toast.success("Success");
            getData();
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log(err)
          toast.error("Something Went Wrong!")
          setLoading(false);
        });
    }
  }

  const increaseItem = (id, type) => {
    if (!loading) {
      setLoading(true);
      let cartTemp = [];
      let temp = Object.assign([], user?.cart);
      temp?.forEach(item => {
        if (item?.productId == id) {
          if (type == 'inc')
            cartTemp.push({ ...item, quen: item.quen + 1 });
          else
            cartTemp.push({ ...item, quen: item.quen - 1 });
        } else {
          cartTemp.push(item);
        }
      })
      put("/user/update", { token: user?.token, cart: cartTemp })
        .then(res => {
          setLoading(false);
          if (res?.statusCode == 200) {
            // toast.success("Success");
            getData();
          } else {
            toast.error(res?.error);
          }
        })
        .catch(err => {
          console.log(err)
          toast.error("Something Went Wrong!")
          setLoading(false);
        });
    }
  }

  console.log('cart data', data)

  return (
    <Fragment>
      <SEO
        titleTemplate="Cart"
        description="Cart page of flone react minimalist eCommerce template."
      />

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Cart", path: process.env.PUBLIC_URL + pathname }
          ]}
        />
        <div className="cart-main-area pt-90 pb-100">
          <div className="container">
            {data && data.length >= 1 ? (
              <Fragment>
                <h3 className="cart-page-title">Your cart items</h3>
                <div className="row">
                  <div className="col-12">
                    <div className="table-content table-responsive cart-table-content">
                      <table>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Unit Price</th>
                            <th>Qty</th>
                            <th>Subtotal</th>
                            <th>action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, key) => {
                            let cartItem = item?.productData;
                            const discountedPrice = getDiscountPrice(
                              cartItem.price,
                              cartItem.discount
                            );
                            const finalProductPrice = (
                              cartItem.price * currency.currencyRate
                            ).toFixed(2);
                            const finalDiscountedPrice = (
                              discountedPrice * currency.currencyRate
                            ).toFixed(2);

                            discountedPrice != null
                              ? (cartTotalPrice +=
                                finalDiscountedPrice * cartItem.quantity)
                              : (cartTotalPrice +=
                                finalProductPrice * cartItem.quantity);
                            return (
                              <tr key={key}>
                                <td className="product-thumbnail">
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/product/" +
                                      cartItem.id
                                    }
                                  >
                                    <img
                                      className="img-fluid"
                                      src={
                                        cartItem.images[0]
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </td>

                                <td className="product-name">
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/product/" +
                                      cartItem.id
                                    }
                                  >
                                    {cartItem.title}
                                  </Link>
                                  {/* {cartItem.selectedProductColor &&
                                    cartItem.selectedProductSize ? (
                                    <div className="cart-item-variation">
                                      <span>
                                        Color: {cartItem.selectedProductColor}
                                      </span>
                                      <span>
                                        Size: {cartItem.selectedProductSize}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )} */}
                                </td>

                                <td className="product-price-cart">
                                  <Fragment>
                                    <span className="amount old">
                                      ₹ {cartItem?.offPrice}
                                    </span>
                                    <span className="amount">
                                      ₹ {cartItem?.price}
                                    </span>
                                  </Fragment>
                                </td>

                                <td className="product-quantity">
                                  <div className="cart-plus-minus">
                                    <button
                                      className="dec qtybutton"
                                      onClick={() => increaseItem(item?.productId, 'dec')}
                                    >
                                      -
                                    </button>
                                    <input
                                      className="cart-plus-minus-box"
                                      type="text"
                                      value={item?.quen}
                                      readOnly
                                    />
                                    <button
                                      className="inc qtybutton"
                                      onClick={() => increaseItem(item?.productId, 'inc')}
                                      disabled={
                                        cartItem !== undefined &&
                                        cartItem.quantity &&
                                        cartItem.quantity >=
                                        cartItemStock(
                                          cartItem,
                                          cartItem.selectedProductColor,
                                          cartItem.selectedProductSize
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </td>
                                <td className="product-subtotal">
                                  ₹ {item?.quen * cartItem?.price}
                                </td>

                                <td className="product-remove">
                                  <button
                                    onClick={() => deleteCart(cartItem?._id)}
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="cart-shiping-update-wrapper">
                      <div className="cart-shiping-update">
                        <Link
                          to={process.env.PUBLIC_URL + "/shop"}
                        >
                          Continue Shopping
                        </Link>
                      </div>
                      <div className="cart-clear">
                        <button onClick={deleteAll}>
                          Clear Shopping Cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="cart-tax">
                      <div className="title-wrap">
                        <h4 className="cart-bottom-title section-bg-gray">
                          Estimate Shipping And Tax
                        </h4>
                      </div>
                      <div className="tax-wrapper">
                        <p>
                          Enter your destination to get a shipping estimate.
                        </p>
                        <div className="tax-select-wrapper">
                          <div className="tax-select">
                            <label>* Country</label>
                            <select className="email s-email s-wid">
                              <option>Bangladesh</option>
                              <option>Albania</option>
                              <option>Åland Islands</option>
                              <option>Afghanistan</option>
                              <option>Belgium</option>
                            </select>
                          </div>
                          <div className="tax-select">
                            <label>* Region / State</label>
                            <select className="email s-email s-wid">
                              <option>Bangladesh</option>
                              <option>Albania</option>
                              <option>Åland Islands</option>
                              <option>Afghanistan</option>
                              <option>Belgium</option>
                            </select>
                          </div>
                          <div className="tax-select">
                            <label>* Zip/Postal Code</label>
                            <input type="text" />
                          </div>
                          <button className="cart-btn-2" type="submit">
                            Get A Quote
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="discount-code-wrapper">
                      <div className="title-wrap">
                        <h4 className="cart-bottom-title section-bg-gray">
                          Use Coupon Code
                        </h4>
                      </div>
                      <div className="discount-code">
                        <p>Enter your coupon code if you have one.</p>
                        <form>
                          <input type="text" required name="name" />
                          <button className="cart-btn-2" type="submit">
                            Apply Coupon
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="grand-totall">
                      <div className="title-wrap">
                        <h4 className="cart-bottom-title section-bg-gary-cart">
                          Cart Total
                        </h4>
                      </div>
                      <h5>
                        Total products{" "}
                        <span>
                          {totalProduct}
                        </span>
                      </h5>

                      <h4 className="grand-totall-title">
                        Grand Total{" "}
                        <span>
                          ₹ {grandTotal}
                        </span>
                      </h4>
                      <Link to={process.env.PUBLIC_URL + "/checkout"}>
                        Proceed to Checkout
                      </Link>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-empty-area text-center">
                    <div className="item-empty-area__icon mb-30">
                      <i className="pe-7s-cart"></i>
                    </div>
                    <div className="item-empty-area__text">
                      No items found in cart <br />{" "}
                      <Link to={process.env.PUBLIC_URL + "/shop"}>
                        Shop Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default OrderList;

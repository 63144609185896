import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function InThePress() {
    return (
        <>
            <div className="inner_header">
                <Header/>
            </div>
            <section className="hero-section inner-banner">
                <div className="banner-content">
                    <h1 className="wow fadeInUp" data-wow-delay="0.4s">In the press</h1>
                </div>
            </section>
            <div className="section-six pt-5">
                <div className="row">
                    <div className="col-md-4 col-sm-6 img-slice-wrap img-slice-wrap-home over-hide">
                        <a className="wow fadeInUp" data-wow-delay="0.4s" href="https://emag.youandi.com/3688609/You-I-Monthly-Magazine/April-2023-Issue-16-5-Mouni-Roy-The-Wedding-Special-Edition#dual/80/1" target="_blank">
                            <div className="card inpress_card">
                                <img src="assets/images/inpress/inpress.jpeg" alt=""/>
                                    <span>preserving the pristine craft of paradise</span>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    )
}
